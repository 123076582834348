import { useStytch } from "@stytch/react";
import { User } from "../types/User";

const UserService = () => {
    const stytch = useStytch();

    const getFavoriteMeets = async (): Promise<string[]> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorites`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem fetching favorite meets:', error);
            return [];
        }
    }

    const addFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/favorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem adding favorite meet:', error);
            throw error;
        }
    }

    const removeFavoriteMeet = async (meetId: string): Promise<void> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/unfavorite`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
                body: JSON.stringify({ meetId }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
        } catch (error) {
            console.error('There was a problem removing favorite meet:', error);
            throw error;
        }
    }

    const getMe = async (): Promise<User | null> => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
                headers: {
                    'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('There was a problem fetching user:', error);
            return null;
        }
    }

    return {
        getFavoriteMeets,
        addFavoriteMeet,
        removeFavoriteMeet,
        getMe
    }
}

export default UserService;