import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import Home from './pages/Home';
import CreateEvent from './pages/CreateEvent';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';  // Adjust the path as needed
import { StytchProvider } from '@stytch/react';
import { StytchUIClient } from '@stytch/vanilla-js';
import TokenAuthenticator from './TokenAuthenticator';
import Profile from './pages/Profile';
import { Authenticate } from './pages/Authenticate';
import ProtectedRoutes from './pages/ProtectedRoutes';
import Login from './pages/Login'; // Make sure to create this component

const stytch = new StytchUIClient(process.env.REACT_APP_STYTCH_PUBLIC_TOKEN || "");

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <StytchProvider stytch={stytch}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <TokenAuthenticator>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/" element={<Authenticate />} />
              <Route element={<ProtectedRoutes />}>
                <Route path="/home" element={<Home />} />
                <Route path="/home/:meetId" element={<Home />} />
                <Route path="/create" element={<CreateEvent />} />
                <Route path="/edit/:meetId" element={<CreateEvent />} />
                <Route path="/profile" element={<Profile />} />
              </Route>
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </TokenAuthenticator>
        </BrowserRouter>
      </ThemeProvider>
    </StytchProvider>
  </React.StrictMode>
);
