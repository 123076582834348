import React, { useState, useEffect, useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { Event } from '../../types/Event';

type FilterBarProps = {
    events: Event[];
    onFilterChange: (selectedKeywords: string[]) => void;
}

const FilterBar = ({ onFilterChange, events }: FilterBarProps) => {
    const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

    const keywords = useMemo(() => {
        const keywordCounts = events.reduce((acc, event) => {
            event.keywords.forEach(keyword => {
                acc[keyword] = (acc[keyword] || 0) + 1;
            });
            return acc;
        }, {} as Record<string, number>);

        return Object.entries(keywordCounts)
            .sort((a, b) => b[1] - a[1])
            .map(([keyword]) => keyword);
    }, [events]);

    const handleKeywordClick = (keyword: string) => {
        setSelectedKeywords(prev => {
            const newSelected = prev.includes(keyword)
                ? prev.filter(k => k !== keyword)
                : [...prev, keyword];
            onFilterChange(newSelected);
            return newSelected;
        });
    };

    return (
        <Box sx={{ display: 'flex', gap: 1, p: 2, overflowX: 'auto' }}>
            {keywords.map((keyword) => (
                <Button
                    key={keyword}
                    onClick={() => handleKeywordClick(keyword)}
                    size="large"
                    variant="contained"
                    color={selectedKeywords.includes(keyword) ? "primary" : "secondary"}
                    sx={(theme) => ({
                        height: '32px',
                        minWidth: 'auto',
                        whiteSpace: 'nowrap',
                        mr: 0,
                        mb: 0,
                    })}
                >
                    {keyword}
                </Button>
            ))}
        </Box>
    )
}

export default FilterBar;