import React, { useMemo } from 'react';
import { Box, IconButton } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeIcon from '@mui/icons-material/Home';
import AddIcon from '@mui/icons-material/Add';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { useLocation, useNavigate } from 'react-router-dom';

const NavigationBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const isLocationHome = useMemo(() => location.pathname.includes("/home"), [location.pathname]);
    const isLocationCreate = useMemo(() => location.pathname.includes("/create"), [location.pathname]);
    const isLocationProfile = useMemo(() => location.pathname.includes("/profile"), [location.pathname]);

    return (
        <Box sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            width: '100%',
            mt: 'auto',
            bgcolor: theme.palette.background.paper,
            p: 1,
            display: 'flex',
            justifyContent: 'space-around',
            '@media (min-width: 768px)': {
                position: 'fixed', // Change from 'sticky' to 'fixed'
                flexDirection: 'column',
                top: 0,
                left: 0, // Add this to ensure it's aligned to the left
                height: '100%', // Change from '100vh' to '100%'
                width: '60px',
                justifyContent: 'flex-start',
                p: 1,
                borderTop: 'none', // Remove top border for desktop view
                borderRight: '1px solid #293038', // Add right border instead
            },
            'svg': {
                fontSize: 20
            }
        })}>
            <IconButton onClick={() => navigate("/home")}>
                {isLocationHome ? <HomeIcon /> : <HomeOutlinedIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />}
            </IconButton>
            <IconButton onClick={() => navigate("/create")}>
                {isLocationCreate ? <AddIcon /> : <AddOutlinedIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />}
            </IconButton>
            <IconButton onClick={() => navigate("/profile")}>
                {isLocationProfile ? <PersonIcon /> : <PersonOutlinedIcon sx={(theme) => ({ color: theme.palette.text.secondary })} />}
            </IconButton>
        </Box >
    );
}

export default NavigationBar;
