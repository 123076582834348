import { useStytch, useStytchUser } from "@stytch/react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FullScreenLoader from "./components/loader/FullScreenLoader";

/*
During both the Magic link and OAuth flow, Stytch will redirect the user back to your application to a specified redirect URL (see Login.js). 
Stytch will append query parameters to the redirect URL which are then used to complete the authentication flow. 
A redirect URL for this example app will look something like: http://localhost:3000/?stytch_token_type=magic_links&token=abc123

TokenAuthenticator will detect the presence of a token in the query parameters, and attempt to authenticate it.
On successful authentication, a session will be created and the user will be shown Profile.js 
*/
const TokenAuthenticator = ({ children }: any) => {
    const stytch = useStytch();
    const { user } = useStytchUser();
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const hasAttemptedAuth = useRef(false);

    useEffect(() => {
        const authenticateToken = async () => {
            if (hasAttemptedAuth.current) return;

            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get("token");
            const tokenType = queryParams.get("stytch_token_type");
            const eventId = queryParams.get("event_id");

            if (token && tokenType && !user && !isAuthenticating) {
                hasAttemptedAuth.current = true;
                setIsAuthenticating(true);
                try {
                    if (tokenType === "magic_links") {
                        await stytch.magicLinks.authenticate(token, {
                            session_duration_minutes: 60,
                        });
                    } else if (tokenType === "oauth") {
                        await stytch.oauth.authenticate(token, {
                            session_duration_minutes: 60,
                        });
                    }
                    if (eventId) {
                        navigate(`/home/${eventId}`, { replace: true });
                    } else {
                        navigate("/home", { replace: true });
                    }
                } catch (err) {
                    navigate("/login?error=Authentication failed. Please try again.", { replace: true });
                } finally {
                    setIsAuthenticating(false);
                }
            }
        };

        authenticateToken();
    }, [stytch, user, navigate, location, isAuthenticating]);

    // If authenticating, show the full-screen loader
    if (isAuthenticating) {
        return <FullScreenLoader />;
    }

    return children;
};

export default TokenAuthenticator;