import React from 'react';
import { Box, Typography, Button, } from '@mui/material';
import NavigationBar from '../components/navigation-bar/NavigationBar';
import { useStytchUser, useStytch } from '@stytch/react';

const Profile = () => {
    const stytch = useStytch();
    const { user } = useStytchUser();

    const handleLogout = () => {
        stytch.session.revoke();
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column-reverse', width: '100%' }}>
            <NavigationBar />
            <Box
                sx={{
                    display: 'flex',
                    marginTop: '72px',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    backgroundColor: '#111418',
                    color: 'white',
                }}
            >
                <Typography variant="h4" align='center'>
                    Profiel
                </Typography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: "100px",
                        width: "300px"
                    }}>
                    <Typography fontWeight={800} variant="body1">{`Email: ${user?.emails[0].email}` || 'User email not available'}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{
                            mt: 2,
                            width: '100%',
                        }}
                        onClick={handleLogout}
                    >
                        Uitloggen
                    </Button>
                </Box>
            </Box>
        </Box >
    );
};

export default Profile;
