import React, { useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Typography } from '@mui/material';
import EventForm from '../components/event-form/EventForm';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import { Event } from '../types/Event';
import EventService from '../services/eventService';
import FullScreenLoader from '../components/loader/FullScreenLoader';

type CreateEventProps = {}

const CreateEvent = (props: CreateEventProps) => {
    const navigate = useNavigate();
    const { meetId } = useParams<{ meetId: string }>();
    const [event, setEvent] = useState<Event | null>(null);
    const eventService = EventService();
    const [loading, setLoading] = useState(meetId ? true : false);


    useEffect(() => {
        if (meetId) {
            eventService.getEvent(meetId).then(setEvent).finally(() => setLoading(false));
        }
    }, [meetId]);

    return (
        <Box sx={{
            '@media (min-width: 768px)': {
                display: 'flex',
                flexDirection: 'row-reverse',
                width: '100%'
            }
        }}>
            <>
                <IconButton
                    onClick={() => navigate("/home")}
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: 2,
                    }}>
                    <CloseIcon />
                </IconButton>
                <Box
                    sx={(theme) => ({
                        display: 'flex',
                        marginTop: '72px',
                        flexDirection: 'column',
                        width: '100%',
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                    })}
                >
                    <Typography variant="h4" align='center'>
                        {meetId ? 'Bewerk meet' : 'Nieuwe meet'}
                    </Typography>
                    <EventForm event={event} loading={loading} />
                </Box>
            </>
        </Box>
    )
}

export default CreateEvent;