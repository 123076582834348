import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useStytchUser, useStytch } from "@stytch/react";
import FullScreenLoader from "../components/loader/FullScreenLoader";
import MessagePage from "./MessagePage";
import { Button } from "@mui/material";

const ProtectedRoutes: React.FC = () => {
    const { user } = useStytchUser();
    const stytch = useStytch();
    const [isWhitelisted, setIsWhitelisted] = useState<boolean | null>(null);

    useEffect(() => {
        const checkWhitelistStatus = async () => {
            if (user) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/me`, {
                        headers: {
                            'Authorization': `Bearer ${stytch.session.getTokens()?.session_token}`,
                        },
                    });

                    if (response.status === 403) {
                        setIsWhitelisted(false);
                    } else if (response.ok) {
                        const userData = await response.json();
                        setIsWhitelisted(userData.isWhitelisted);
                    } else {
                        console.error('Unexpected response status:', response.status);
                        setIsWhitelisted(false);
                    }
                } catch (error) {
                    console.error('Error checking whitelist status:', error);
                    setIsWhitelisted(false);
                }
            }
        };

        checkWhitelistStatus();
    }, [user, stytch.session]);

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    if (isWhitelisted === null) {
        return <FullScreenLoader />;
    }

    if (isWhitelisted === false) {
        return <MessagePage title="Toegang geweigerd" message="Je hebt nog geen toegang.">
            <Button variant="contained" color="primary" onClick={() => stytch.session.revoke()}>Uitloggen</Button>
        </MessagePage>
    }

    return <Outlet />;
};

export default ProtectedRoutes;