import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardHeader, CardMedia, CardActions, IconButton, Typography, Box } from '@mui/material';
import StarIcon from '@mui/icons-material/StarBorderRounded';
import IosShareIcon from '@mui/icons-material/IosShare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { Navigate, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Menu, MenuItem } from '@mui/material';

type EventCardProps = {
    id: string;
    title: string;
    date: string;
    location: string;
    imageUrl?: string;
    isAdmin: boolean;
    isFavorite: boolean;
    onFavorite: () => void;
    onHighlight: () => void;
    onAdminAction: () => void;
    isHighlighted?: boolean;
    needsAdminAttention?: boolean;
    onEdit: () => void;
    isCreator: boolean;
}

const EventCard: React.FC<EventCardProps> = ({
    id,
    title,
    date,
    location,
    imageUrl,
    isAdmin,
    isFavorite,
    onFavorite,
    onHighlight,
    onAdminAction,
    isHighlighted = false,
    needsAdminAttention = false,
    onEdit,
    isCreator,
}) => {
    const formattedDate = format(new Date(date), "d MMMM yyyy 'om' HH:mm", { locale: nl });
    const navigate = useNavigate();
    const [animateFavorite, setAnimateFavorite] = useState(false);
    const [animateShare, setAnimateShare] = useState(false);
    const [animateOptions, setAnimateOptions] = useState(false);
    const [isFavorited, setIsFavorited] = useState(isFavorite);
    const [isSharing, setIsSharing] = useState(false);
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setIsFavorited(isFavorite);
    }, [isFavorite]);

    const handleFavoriteClick = () => {
        setAnimateFavorite(false);
        setIsFavorited(!isFavorited);
        setAnimateFavorite(true);
        onFavorite();
    };

    const handleShare = () => {
        const newUrl = `/home/${id}`;
        window.history.pushState(null, '', newUrl);
        setAnimateShare(false);
        onHighlight();
        if (navigator.share) {
            setAnimateShare(true);
            setIsSharing(true);
            navigator.share({
                title: title,
                text: `Check out this event: ${title} on ${formattedDate} at ${location}`,
                url: `${window.location.origin}/?event_id=${id}`,
            })
                .then(() => { navigate(`/home/`); setIsSharing(false); })
                .catch((error) => { console.error('Error sharing:', error); setIsSharing(false); navigate(`/home/`); });
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setAnimateOptions(true);
        setIsOptionsOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setAnimateOptions(false);
        setIsOptionsOpen(false);
    };

    const handleEditClick = () => {
        onEdit();
        handleMenuClose();
    };

    return (
        <Card sx={{
            width: '100%',
            bgcolor: 'background.paper',
            transition: 'box-shadow 0.3s ease-in-out',
            boxShadow: isHighlighted
                ? '0 0 100px 1px rgba(0, 180, 230, 0.2)'
                : 'none',
        }}>
            {imageUrl && (
                <CardMedia
                    component="img"
                    height="140"
                    image={imageUrl}
                    alt={title}
                />
            )}
            <CardHeader
                title={
                    <Typography variant="h6" component="div">
                        {needsAdminAttention && isAdmin && "⚠️ "}
                        {title}
                    </Typography>
                }
                subheader={
                    <Box>
                        <Typography variant="body2" color="text.secondary">{formattedDate}</Typography>
                        <Typography variant="body2" color="text.secondary">{location}</Typography>
                    </Box>
                }
            />
            <CardActions disableSpacing>
                <IconButton
                    aria-label="add to favorites"
                    onClick={handleFavoriteClick}
                    sx={{ height: '40px', width: '40px' }}
                >
                    {!isFavorited ? (
                        <StarIcon />
                    ) : (
                        <Typography
                            sx={{
                                fontSize: '20px',
                                animation: animateFavorite && isFavorited ? 'twistBounce 1s' : 'none',
                                '@keyframes twistBounce': {
                                    '0%': { transform: 'perspective(400px) scale(0) rotateY(0)' },
                                    '50%': { transform: 'perspective(400px) scale(1.5) rotateY(360deg)' },
                                    '100%': { transform: 'perspective(400px) scale(1) rotateY(360deg)' },
                                }
                            }}
                        >
                            ⭐️
                        </Typography>
                    )}
                </IconButton>
                <IconButton
                    aria-label="share"
                    onClick={handleShare}
                    sx={{ height: '40px', width: '40px' }}
                >
                    {!isSharing ? (
                        <IosShareIcon sx={{ fontSize: "20px" }} />
                    ) : (
                        <Typography
                            sx={{
                                fontSize: '20px',
                                animation: animateShare && isSharing ? 'bounceShare 0.3s' : 'none',
                                '@keyframes bounceShare': {
                                    '0%': { transform: 'perspective(400px) scale(0)' },
                                    '50%': { transform: 'perspective(400px) scale(1.5)' },
                                    '100%': { transform: 'perspective(400px) scale(1)' },
                                }
                            }}
                        >
                            🔗
                        </Typography>
                    )}
                </IconButton>
                {(isAdmin || isCreator) && (
                    <Box sx={{ marginLeft: 'auto', display: 'flex' }}>
                        <IconButton aria-label="more options" onClick={handleMenuOpen}>
                            {!isOptionsOpen ? (<MoreVertIcon sx={{ fontSize: "20px" }} />
                            ) : <Typography
                                sx={{
                                    animation: animateOptions && isOptionsOpen ? 'bounceOptions 0.3s' : 'none',
                                    '@keyframes bounceOptions': {
                                        '0%': { transform: 'perspective(400px) scale(0)' },
                                        '50%': { transform: 'perspective(400px) scale(1.5)' },
                                        '100%': { transform: 'perspective(400px) scale(1)' },
                                    }
                                }}
                            >
                                🛠
                            </Typography>}
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleEditClick}>Bewerken</MenuItem>
                            {/* Add more menu items here as needed */}
                        </Menu>
                    </Box>
                )}
            </CardActions>
        </Card>
    );
};

export default EventCard;