import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import EventCard from '../event-card/EventCard';
import { Event } from '../../types/Event';
import FullScreenLoader from '../loader/FullScreenLoader';
import UserService from '../../services/userService';
import { User } from '../../types/User';

type EventListProps = {
    loading: boolean;
    searchQuery: string;
    events: Event[];
    selectedKeywords: string[];
    highlightedMeetId: string | null;
    showFavorites: boolean;
    user: User | null;
    onScreenClick: () => void;
    onHighlight: (id: string) => void;
    onEdit: (id: string) => void;
}

const EventList = ({ searchQuery, events, loading, selectedKeywords, user, highlightedMeetId, showFavorites, onScreenClick, onHighlight, onEdit }: EventListProps) => {
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [favorites, setFavorites] = useState<string[]>([]);
    const [isScrolling, setIsScrolling] = useState(false);
    const userService = UserService();

    useEffect(() => {
        const fetchFavorites = async () => {
            try {
                const favoriteMeetIds = await userService.getFavoriteMeets();
                setFavorites(favoriteMeetIds);
            } catch (error) {
                console.error('Failed to fetch favorite meets:', error);
                setFavorites([]);
            }
        };

        fetchFavorites();
    }, []);



    useEffect(() => {
        const filtered = events.filter(event =>
            (event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                event.location.toLowerCase().includes(searchQuery.toLowerCase())) &&
            (selectedKeywords.length === 0 || selectedKeywords.every(kw => event.keywords.includes(kw)))
        );
        setFilteredEvents(filtered);
    }, [searchQuery, selectedKeywords, events]);

    useEffect(() => {
        if (highlightedMeetId && filteredEvents.length > 0 && !isScrolling) {
            setIsScrolling(true);
            const highlightedElement = document.getElementById(`event-${highlightedMeetId}`);
            if (highlightedElement) {
                highlightedElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [highlightedMeetId, filteredEvents]);

    const handleFavorite = async (eventId: string) => {
        try {
            if (favorites.includes(eventId)) {
                await userService.removeFavoriteMeet(eventId);
                setFavorites(prevFavorites => prevFavorites.filter(id => id !== eventId));
            } else {
                await userService.addFavoriteMeet(eventId);
                setFavorites(prevFavorites => [...prevFavorites, eventId]);
            }
        } catch (error) {
            console.error('Failed to update favorite status:', error);
        }
    };

    useEffect(() => { setIsScrolling(false) }, [highlightedMeetId])

    if (loading || !user) {
        return <FullScreenLoader />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
                justifyContent: 'center',
                p: 2,
                marginBottom: '73px',
                position: 'relative',
            }}
            onClick={onScreenClick}
        >
            {filteredEvents.filter(event => showFavorites ? favorites.includes(event.id) : true).map((event) => (
                <Box
                    key={event.id}
                    id={`event-${event.id}`}
                    sx={{
                        width: '100%',
                        transition: 'filter 0.3s ease-in-out, opacity 0.3s ease-in-out',
                        filter: highlightedMeetId && event.id !== highlightedMeetId ? 'blur(2px)' : 'none',
                        opacity: highlightedMeetId && event.id !== highlightedMeetId ? 0.7 : 1,
                    }}
                >
                    <EventCard
                        id={event.id}
                        isHighlighted={event.id === highlightedMeetId}
                        title={event.title}
                        date={event.date}
                        location={event.location}
                        imageUrl={event.imageUrl}
                        isAdmin={user.isAdmin}
                        isFavorite={favorites.includes(event.id)}
                        onFavorite={() => handleFavorite(event.id)}
                        onHighlight={() => onHighlight(event.id)}
                        needsAdminAttention={event.needsAdminAttention}
                        onEdit={() => onEdit(event.id)}
                        isCreator={user.hosted_meets.some(hostedMeet => hostedMeet.id === event.id)} // You'll need to implement this method
                        onAdminAction={() => {/* Implement admin action logic */ }}
                    />
                </Box>
            ))}
        </Box>
    );
};

export default EventList;
