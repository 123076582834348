import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { useStytch, useStytchUser } from '@stytch/react';
import { Navigate, useLocation } from 'react-router-dom';
import theme from '../theme';
import loginImage1 from '../assets/carmeeter-logo-2.png'; // Make sure this image exists in your assets folder
import loginImage2 from '../assets/carmeeter-logo-3.png'; // Make sure this image exists in your assets folder

const Login = () => {
    const { user } = useStytchUser();
    const stytch = useStytch();
    const location = useLocation();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const eventId = new URLSearchParams(location.search).get('event_id');
    const [isEmailSent, setIsEmailSent] = useState(false);
    const [randomImage, setRandomImage] = useState('');

    useEffect(() => {
        setRandomImage(Math.random() < 0.5 ? loginImage1 : loginImage2);
    }, []);

    const getRedirectUrl = () => {
        const baseUrl = process.env.REACT_APP_LOGIN_REDIRECT_URL || 'http://localhost:3000/';
        return eventId ? `${baseUrl}?event_id=${eventId}` : baseUrl;
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            await stytch.magicLinks.email.send(email, {
                login_magic_link_url: getRedirectUrl(),
                signup_magic_link_url: getRedirectUrl(),
            });
            setIsEmailSent(true);
            // Success message or redirect to a "Check your email" page
        } catch (error) {
            setIsEmailSent(false);
            setError('An error occurred. Please try again.');
            console.error('Login error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTryAgain = () => {
        setIsEmailSent(false);
        setEmail('');
        setError('');
    };

    if (user) {
        return <Navigate to={eventId ? `/home/${eventId}` : "/home"} replace />;
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden',
            paddingTop: "300px",
        }}>
            {error && (
                <Typography color="error" sx={{ marginBottom: 2 }}>
                    {error}
                </Typography>
            )}
            {randomImage && (
                <Box
                    component="img"
                    src={randomImage}
                    alt="Login"
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        width: '300px',
                        transform: 'translateX(-50%)',
                        animation: 'fadeInSlideUp 2s ease-out',
                        '@keyframes fadeInSlideUp': {
                            '0%': {
                                opacity: 0,
                                transform: 'translate(-50%, 100%)',
                            },
                            '50%': {
                                opacity: 0,
                                transform: 'translate(-50%, 50%)',
                            },
                            '100%': {
                                opacity: 1,
                                transform: 'translate(-50%, 0)',
                            },
                        },
                    }}
                />
            )}
            <Box sx={{
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                top: '300px',
                left: 0,
                width: '100%',
                height: 'auto',
                zIndex: 1000,
            }}>
                <Box
                    component="form"
                    onSubmit={handleLogin}
                    sx={{
                        width: "300px",
                        backgroundColor: theme.palette.background.paper,
                        padding: 3,
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {!isEmailSent ? (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Log in of meld je aan
                            </Typography>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{ mb: 2 }}
                                required
                                autoComplete="email"
                                inputProps={{
                                    autoCapitalize: "none",
                                    autoCorrect: "off"
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                disabled={isLoading}
                                sx={{ mb: 2 }}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Verstuur link'}
                            </Button>
                        </>
                    ) : (
                        <>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Email verzonden
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
                                Check de inbox van {email} voor een login link.
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={handleTryAgain}
                                sx={{ mb: 2 }}
                            >
                                Probeer opnieuw
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
