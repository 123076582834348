import { Navigate } from "react-router-dom";
import Login from "./Login";
import { useStytchSession } from "@stytch/react";
import { useEffect, useState } from "react";
import MessagePage from "./MessagePage";
import { useLocation } from "react-router-dom";

export const Authenticate = (): JSX.Element => {
    const { session } = useStytchSession();
    const [isChecking, setIsChecking] = useState(true);
    const location = useLocation();

    useEffect(() => {
        setIsChecking(false);
    }, [session]);

    if (isChecking) {
        return <MessagePage title="" message="" />;
    }

    if (session) {
        const searchParams = new URLSearchParams(location.search);
        const eventId = searchParams.get('event_id');
        return <Navigate to={eventId ? `/home/${eventId}` : "/home"} />;
    }

    return <Login />;
};