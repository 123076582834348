import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

interface MessagePageProps {
    title: string;
    message: string;
    children?: ReactNode;
}

const MessagePage = ({ title, message, children }: MessagePageProps) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            padding: "24px",
            marginTop: "180px",
        }}>
            <h1>{title}</h1>
            <h4>{message}</h4>
            {children}
        </Box>
    )
}

export default MessagePage;