import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3B4751',
        },
        secondary: {
            main: '#1c2126',
        },
        background: {
            default: '#111418',
            paper: '#1c2126',
        },
        text: {
            primary: '#ffffff',
            secondary: '#9dabb8',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: 'none',
                    fontWeight: 500,
                },
            },
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        padding: '4px 8px',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '6px 16px',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        padding: '8px 22px',
                    },
                },
            ],
        },
    },
});

export default theme;
