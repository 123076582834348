import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import FilterBar from '../components/filter-bar/FilterBar';
import EventList from '../components/event-list/EventList';
import TopBar from '../components/top-bar/TopBar';
import NavigationBar from '../components/navigation-bar/NavigationBar';
import EventService from '../services/eventService';
import { Event } from '../types/Event';
import UserService from '../services/userService';
import { User } from '../types/User';

const Home: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const eventService = EventService();
  const userService = UserService();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState<Event[]>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
  const [highlightedMeetId, setHighlightedMeetId] = useState<string | null>(null);
  const { meetId } = useParams<{ meetId: string }>();
  const navigate = useNavigate();
  const [showFavorites, setShowFavorites] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await userService.getMe();
        setUser(user);
      } catch (error) {
        console.error('Failed to fetch user:', error);
      }
    };
    fetchUser();
    if (!events.length) {
      const fetchEvents = async () => {
        try {
          setIsLoading(true);
          const fetchedEvents = await eventService.getEvents();
          setEvents(fetchedEvents);
          setFilteredEvents(fetchedEvents);
        } catch (error) {
          console.error('Failed to fetch events:', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchEvents();
    }

    if (meetId) {
      setHighlightedMeetId(meetId);
    } else {
      setHighlightedMeetId(null);
    }
  }, [meetId]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    filterEvents(query, selectedKeywords);
  };

  const handleFilterChange = (newSelectedKeywords: string[]) => {
    setSelectedKeywords(newSelectedKeywords);
    filterEvents(searchQuery, newSelectedKeywords);
  };

  const handleFavoriteFilter = (showFavorites: boolean) => {
    setShowFavorites(showFavorites);
    // filterEvents(searchQuery, selectedKeywords);
  };

  const filterEvents = (query: string, keywords: string[]) => {
    const filtered = events.filter(event =>
      (query === '' || event.title.toLowerCase().includes(query.toLowerCase()) ||
        event.location.toLowerCase().includes(query.toLowerCase())) &&
      (keywords.length === 0 || keywords.every(kw => event.keywords.includes(kw)))
    );
    setFilteredEvents(filtered);
  };

  const handleScreenClick = () => {
    if (highlightedMeetId) {
      setHighlightedMeetId(null);
      navigate('/home');
    }
  };

  return (
    <Box sx={(theme) => ({
      '@media (min-width: 768px)': {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '100%'
      }
    })}>
      <>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.primary,
            padding: 0,
            '@media (min-width: 768px)': {
              paddingLeft: '60px',
            }
          })}
        >
          <TopBar onSearch={handleSearch} onFavoriteFilter={handleFavoriteFilter} />
          <FilterBar events={filteredEvents} onFilterChange={handleFilterChange} />
          <EventList
            user={user}
            events={filteredEvents}
            searchQuery={searchQuery}
            loading={isLoading}
            selectedKeywords={selectedKeywords}
            highlightedMeetId={highlightedMeetId}
            showFavorites={showFavorites}
            onScreenClick={handleScreenClick}
            onHighlight={(id: string) => navigate(`/home/${id}`)}
            onEdit={(id: string) => navigate(`/edit/${id}`, { state: { from: '/' } })}
          />
        </Box>
      </>
      <NavigationBar />
    </Box>
  );
};

export default Home;
